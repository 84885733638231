import React, { Component } from 'react';
import { Link,  } from "react-router-dom";
import { connect } from "react-redux";
import { Menu,  Button,   Icon,  Modal,  Sidebar} from "semantic-ui-react";
import LoginForModal from "../auth/LoginForModal";
import {getUserForAuth, logoutUser,hideSidebar } from "../../actions/authAction";
import { openModal } from "../../actions/modalAction";




class Layout extends Component {
  constructor(props) {
    super(props);
    this.state = {menuVisible: false,modalOpenL: false };
  }

  componentDidUpdate(prevProps) {
    //Typical usage, don't forget to compare the props
    if (this.props.auth.isAuthenticated !== prevProps.auth.isAuthenticated) {
      if(this.props.auth.isAuthenticated) {this.props.getUserForAuth();}
      //window.scroll(0,0)
    }
     }
handleOpenL = () => this.setState({ modalOpenL: true })
handleCloseL = () => this.setState({ modalOpenL: false })

  onLogoutClick = () => {

    this.props.logoutUser();

  };
  handleSidebarHide = () => this.setState({ menuVisible: false })



//########################################



    render () {
const { isAuthenticated,  } = this.props.auth;
const renderLoginLogoutButton=()=>{
return isAuthenticated ? (

<Button onClick={this.onLogoutClick}
color='red'
size="mini"
><Icon name="power" />Sign Out</Button>
) : (
<Button onClick={this.handleOpenL}
color='teal'
size="mini"
><Icon name="power" />Sign In</Button>
)}

//*************************** */
      const able = 'sidebar-border-divider sidebar-div-link'
      const disable = 'sidebar-border-divider sidebar-div-link-disabled'

      const companyRouteHandler = ()=>{
        const moduleAbleDisable = able

        return (
              <React.Fragment>

                <div className='sidebar-border-divider sidebar-padding-head' style={{fontSize:'1.0rem',fontWeight:'bold',backgroundColor:'skyblue'}}>
                Company</div>
                <div className={moduleAbleDisable}>
                <Link to="/company/searchcompanyslist" >
                <b>All Companies</b>
                </Link>
                </div>

                <div className={moduleAbleDisable}>
                <Link to="/company/MyGroup" >
                <b>My Group</b>
                </Link>
                </div>

                <div className={moduleAbleDisable}>
                 <Link to="/company/mycompany" >
                <b>My Company</b>
                </Link>
                </div>

                </React.Fragment>


              )
      }

      const taskTypeRouteHandler = ()=>{
        const moduleAbleDisable = (this.props.auth.userA !== null && this.props.auth.userA.taskModule === "Yes"  )?
           able:disable

        return (
              <React.Fragment>

                <div className='sidebar-border-divider sidebar-padding-head' style={{fontSize:'1.0rem',fontWeight:'bold',backgroundColor:'skyblue'}}>
                Personal Task Type</div>
                  <div className={moduleAbleDisable}>
                  <Link to="/option/tasktypePer/TaskTypePersonalCreate" >
                   <b>Create</b>
                </Link>
                </div>

                <div className={moduleAbleDisable}>
                <Link to="/option/tasktypePer/TaskTypePersonalList" >
                 <b>All Task Type</b>
               </Link>
                </div>
                {/**/}
                <div className='sidebar-border-divider sidebar-padding-head' style={{fontSize:'1.0rem',fontWeight:'bold',backgroundColor:'skyblue'}}>
                Professional Task Type</div>
                  <div className={moduleAbleDisable}>
                  <Link to="/option/tasktypePro/TaskTypeProfessionalCreate" >
                   <b>Create</b>
                </Link>
                </div>

                <div className={moduleAbleDisable}>
                <Link to="/option/tasktypePro/TaskTypeProfessionalList" >
                 <b>All Task Type</b>
               </Link>
                </div>
                </React.Fragment>


              )
      }
      const contactGroupRouteHandler = ()=>{
        const moduleAbleDisable = (this.props.auth.userA !== null && this.props.auth.userA.note_contactModule === "Yes"  )?
           able:disable

        return (
              <React.Fragment>

                <div className='sidebar-border-divider sidebar-padding-head' style={{fontSize:'1.0rem',fontWeight:'bold',backgroundColor:'skyblue'}}>
                Contact Group</div>
                  <div className={moduleAbleDisable}>
                  <Link to="/option/contact/ContactGroupCreate" >
                   <b>Create</b>
                </Link>
                </div>

                <div className={moduleAbleDisable}>
                <Link to="/option/contact/ContactGroupList" >
                 <b>Contact Groups</b>
               </Link>
                </div>

                </React.Fragment>


              )
      }

      // const itemTypeRouteHandler = ()=>{
      //   const moduleAbleDisable = (this.props.auth.userA !== null && this.props.auth.userA.itemLocationModule === "Yes"  )?
      //      able:disable

      //   return (
      //         <React.Fragment>

      //           <div className='sidebar-border-divider sidebar-padding-head' style={{fontSize:'1.0rem',fontWeight:'bold',backgroundColor:'skyblue'}}>
      //           Personal Item Type For Item Location</div>
      //             <div className={moduleAbleDisable}>
      //             <Link to="/option/itemtypePer/ItemTypePersonalCreate" >
      //              <b>Create</b>
      //           </Link>
      //           </div>

      //           <div className={moduleAbleDisable}>
      //           <Link to="/option/itemtypePer/ItemTypePersonalList" >
      //            <b>All Item Type</b>
      //          </Link>
      //           </div>
      //           {/**/}
      //           <div className='sidebar-border-divider sidebar-padding-head' style={{fontSize:'1.0rem',fontWeight:'bold',backgroundColor:'skyblue'}}>
      //         {' '}  Professional Item Type For Item Location {' '} </div>
      //             <div className={moduleAbleDisable}>
      //             <Link to="/option/itemtypePro/ItemTypeProfessionalCreate" >
      //              <b>Create</b>
      //           </Link>
      //           </div>

      //           <div className={moduleAbleDisable}>
      //           <Link to="/option/itemtypePro/ItemTypeProfessionalList" >
      //            <b>All Item Type</b>
      //          </Link>
      //           </div>
      //           </React.Fragment>


      //         )
      // }

      // const lent_borrowedItemTypeRouteHandler = ()=>{
      //   const moduleAbleDisable = (this.props.auth.userA !== null && this.props.auth.userA.lend_borrowModule === "Yes"  )?
      //      able:disable

      //   return (
      //         <React.Fragment>

      //           <div className='sidebar-border-divider sidebar-padding-head' style={{fontSize:'1.0rem',fontWeight:'bold',backgroundColor:'skyblue'}}>
      //           Personal Item Type For Lend/Borrow</div>
      //             <div className={moduleAbleDisable}>
      //             <Link to="/option/lent_borrowedItemtypePer/Lent_BorrowedItemTypePersonalCreate" >
      //              <b>Create</b>
      //           </Link>
      //           </div>

      //           <div className={moduleAbleDisable}>
      //           <Link to="/option/lent_borrowedItemtypePer/Lent_BorrowedItemTypePersonalList" >
      //            <b>All Item Type</b>
      //          </Link>
      //           </div>
      //           {/**/}
      //           <div className='sidebar-border-divider sidebar-padding-head' style={{fontSize:'1.0rem',fontWeight:'bold',backgroundColor:'skyblue'}}>
      //         {' '}  Professional Item Type For Lend/Borrow {' '} </div>
      //             <div className={moduleAbleDisable}>
      //             <Link to="/option/lent_borrowedItemtypePro/Lent_BorrowedItemTypeProfessionalCreate" >
      //              <b>Create</b>
      //           </Link>
      //           </div>

      //           <div className={moduleAbleDisable}>
      //           <Link to="/option/lent_borrowedItemtypePro/Lent_BorrowedItemTypeProfessionalList" >
      //            <b>All Item Type</b>
      //          </Link>
      //           </div>
      //           </React.Fragment>


      //         )
      // }
      const financeRouteHandler = ()=>{
        const moduleAbleDisable = (this.props.auth.userA !== null && (this.props.auth.userA.cashbookModule === "Yes" || this.props.auth.userA.receivable_payableModule === "Yes") )?
           able:disable
        return (
          <React.Fragment>

          <div className='sidebar-border-divider sidebar-padding-head' style={{fontSize:'1.0rem',fontWeight:'bold',backgroundColor:'skyblue'}}>
          Account Name</div>
            <div className={moduleAbleDisable}>
          <Link to="/finance/accName/AccountNameCreate" >
          <b>Create AccountName</b>
          </Link>
          </div>
          <div className={moduleAbleDisable}>
          <Link to="/finance/accName/AccountNameList" >
          <b>All AccountName</b>
          </Link>
          </div>

          <div className='sidebar-border-divider sidebar-padding-head' style={{fontSize:'1.0rem',fontWeight:'bold',backgroundColor:'skyblue'}}>
          Entry Ref</div>
            <div className={moduleAbleDisable}>
          <Link to="/finance/accRef/AccountRefCreate" >
          <b>Create EntryRef</b>
          </Link>
          </div>
          <div className={moduleAbleDisable}>
          <Link to="/finance/accRef/AccountRefList" >
          <b>All EntryRef</b>
          </Link>
          </div>

          <div className='sidebar-border-divider sidebar-padding-head' style={{fontSize:'1.0rem',fontWeight:'bold',backgroundColor:'skyblue'}}>
          Bank Account Name</div>
            <div className={moduleAbleDisable}>
          <Link to="/finance/bankAccName/BankAccountNameCreate" >
          <b>Create Bank Account Name</b>
          </Link>
          </div>
          <div className={moduleAbleDisable}>
          <Link to="/finance/bankAccName/BankAccountNameList" >
          <b>All Bank Account Name</b>
          </Link>
          </div>
          </React.Fragment>
              )
      }

      const dataDownloadRouteHandler = ()=>{
        const moduleAbleDisable = able

        return (
              <React.Fragment>

                <div className='sidebar-border-divider sidebar-padding-head' style={{fontSize:'1.0rem',fontWeight:'bold',backgroundColor:'skyblue'}}>
                Download Data As CSV File</div>
                  <div className={moduleAbleDisable}>
                  <Link to="/option/CSVDownload" >
                   <b>Download Data</b>
                </Link>
                </div>


                </React.Fragment>


              )
      }

//########################################
        return (
            <div  style={{marginTop:"50px",}}>

            <Sidebar.Pushable >
            <Sidebar
            className='body'
            as={Menu}
            animation="overlay"
            icon="labeled"
            // inverted
            vertical
            visible={this.props.visible}
            onClick={this.props.hideSidebar  }

          >
            <br/>
            <div> {renderLoginLogoutButton()}</div>
          <br/>

          {/*  */}
          {companyRouteHandler()}
          {financeRouteHandler()}
          {taskTypeRouteHandler()}
          {contactGroupRouteHandler()}
          {/*{itemTypeRouteHandler()}
          {lent_borrowedItemTypeRouteHandler()}*/}
          {dataDownloadRouteHandler()}

<br/><br/>

              </Sidebar>
          <Sidebar.Pusher
          style={{minHeight:'100vh'}}
          >
          {/* <Button
          style={{color:"SlateBlue",marginLeft:"15px",marginTop:"10px",}}
          inverted
          onClick={() => this.setState({ menuVisible: !this.state.menuVisible })}
          color='blue'
          >
          <Icon name="sidebar" />
          </Button> */}
<div>
         <Modal

      open={this.state.modalOpenL}
      onClose={this.handleCloseL}
      size='tiny'
      // size='small'
    >
        <Modal.Content >
<div><h3>You Are Not Logged In. Please Logged In</h3> </div>
      <LoginForModal handleCloseL={this.handleCloseL}/>
      </Modal.Content>
      <Modal.Actions>


        <Button color='blue' onClick={this.handleCloseL} >
          <Icon name='close' />Cancel
        </Button>

      </Modal.Actions>
    </Modal>
    </div>
        {this.props.children}
         </Sidebar.Pusher>
            </Sidebar.Pushable>

            </div>
        )
    }
}
const mapStateToProps = state => ({
  auth: state.auth,
  visible: state.auth.visible,
  userA: state.auth.userA,
});

export default connect(
  mapStateToProps,
  {getUserForAuth, logoutUser,hideSidebar,openModal } )(Layout);
