import React from "react";
import { connect } from "react-redux";
import MessageModal from './MessageModal';
import LoginModal from './LoginModal';
import ImageZoomModal from './ImageZoomModal';
import SearchUserModal from "./SearchUserModal";



const modalLookup = {
  MessageModal,
   LoginModal,
   ImageZoomModal,
  SearchUserModal,
};

const mapState = state => ({
  currentModal: state.modals
});

const ModalManager = ({ currentModal }) => {
  let renderedModal;

  if (currentModal) {
    const { modalType, modalProps } = currentModal;
    const ModalComponent = modalLookup[modalType];

    renderedModal = <ModalComponent {...modalProps} />;
  }
  return <span>{renderedModal}</span>;
};

export default connect(mapState)(ModalManager);
