import axios from "axios";
import setAuthToken from "../utils/setAuthToken";
import jwt_decode from "jwt-decode";
import { toastr } from "react-redux-toastr";

import { closeModal, openModal } from "./modalAction";

import {
 // GET_ERRORS,
  SET_CURRENT_USER,
  SEARCH_USERS,
  //CREATE_USERS,
  FETCH_USERS,
  FETCH_USER,
  FETCH_USERDETAIL,
  //DELETE_USER,
  UPDATE_USER,

  FETCH_DEMOUSERS,
  FETCH_DEMOUSERDETAIL,

  SHOW_SIDEBAR,
  HIDE_SIDEBAR,
  SHOW_BACKBUTTON ,
  HIDE_BACKBUTTON ,
  //
  FETCH_FINANCE,
  FETCH_ACCOUNTTYPE,
  FETCH_ACCOUNTNAME,
  FETCH_TASK,
} from "./types";


//Update password
export const updatePassword = (userData, history ) => async () => {
  try {
    const res = await axios.post('/api/users/updatePassword', userData);


const message = res.data.msg
    history.goBack()
   toastr.success(message)
  } catch (error) {

    toastr.error("Oops", "Something went wrong.Please Try Again");
  }
};

//Change password
export const changePassword = (id,userData, history ) => async () => {
  try {
    const res = await axios.post(`/api/users/ChangePassword/${id}`, userData);


const message = res.data.msg
    history.goBack()
   toastr.success(message)
  } catch (error) {

    toastr.error("Oops", "Something went wrong.Please Try Again");
  }
};

// Login - Get User Token
export const loginUser = (userData) => async dispatch => {
  try {
    const res = await axios.post("/api/users/login", userData);

    //***************************
    if (res.data.msg ==='errorPass') {
    toastr.error('Password Incorrect');
    }
    if (res.data.msg ==='errorNoUser') {
      toastr.error('Either your Id is Incorrect or there is No User with this Id');
    }


  if(res.data.msg ==='success'){
        const { token } = res.data;


        // Set token to ls
        localStorage.setItem("jwtToken", token);

        // Set token to Auth header
        setAuthToken(token);
        // Decode token to get user data
        const decoded = jwt_decode(token);

        // Set current user
        dispatch(setCurrentUser(decoded));
        dispatch(closeModal());
        dispatch({ type: FETCH_USER, payload: res.data.userA });
        toastr.success('Login Successfully')
  }


  } catch (error) {
    toastr.error("Oops", "Something went wrong.Please Try Again");
  }
};

//##############################
//Form PassReset
export const formPassReset = (userData, history) => async () => {
  try {
    const res = await axios.post('/api/users/resetPasswordToken', userData);

    if(res.data.msg === 'error'){
      toastr.error("Please check you have entered a Wrong Email. Enter your Registered Email")
    }

      if(res.data.msg ==='success'){
        history.goBack()
        toastr.success('Password Reset link is send at your Registered Email.')
      }



  } catch (error) {
    toastr.error("Oops", "Something went wrong.Please Try Again");
  }
};
//****************************
//RESET PASSWORD
export const resetPassLogin = (token, userData, history) => async dispatch => {
  try {
    const res = await axios.put(`/api/users/resetPassword/${token}`, userData);

    if(res.data.msg === 'error'){
      toastr.error("Password Reset link have expired Or Invalid. Please Get Another .")
    }
      if(res.data.msg ==='success'){
        history.goBack()
        dispatch(openModal('LoginModal'));
        toastr.success('Password Changed.You can Login with New Password ')
      }



  } catch (error) {
    toastr.error("Oops", "Something went wrong.Please Try Again");
  }
};
//

// Set logged in user
export const setCurrentUser = decoded => {
  return {
    type: SET_CURRENT_USER,
    payload: decoded
  };
};

// Log user out
export const logoutUser = () => async dispatch => {
  try {
    // const res = await axios.post("/api/users/logout");

    //  if(res.data.msg ==='success'){

  // Remove token from localStorage
  localStorage.removeItem("jwtToken");
  // Remove auth header for future requests
  setAuthToken(false);
  // Set current user to {} which will set isAuthenticated to false
  dispatch(setCurrentUser({}));
  dispatch({ type: FETCH_USER, payload: {userA:{},user:{}} });
  window.location.href = '/';

 // toastr.success('Logout Successfully')

    // }
} catch (error) {
  
  toastr.error("Oops", "Something went wrong.Please Try Again");
}
};
//*************************************************
export const clearStorage = () =>dispatch => {

  // Remove token from localStorage
  localStorage.removeItem("jwtToken");
  // Remove auth header for future requests
  setAuthToken(false);
  // Set current user to {} which will set isAuthenticated to false
  dispatch(setCurrentUser({}));
  window.location.href = '/';

};
//*************************************************
export const clearStorage2 = () =>dispatch => {


  dispatch(openModal('LoginModal'));


};
//####################################################################
//search  Users

export const searchUsers = (
  values,
  limit,
  offset,
  //history
) => async dispatch => {

  try {
    const res = await axios.post("/api/UserSearch", {
      values,
      limit,
      offset
    });


    dispatch({ type: SEARCH_USERS, payload: res.data });
    dispatch(closeModal());
  } catch (error) {
    toastr.error("Oops", "Something went wrong.Please Try Again");
  }
};

//fetch all Users of a company
export const fetchUsers = (id) => async dispatch => {
  try {
    const res = await axios.post(`/api/UserList/${id}`);

    dispatch({ type: FETCH_USERS, payload: res.data });
  } catch (error) {
    toastr.error("Oops", "Something went wrong.Please Try Again");
  }
};

//fetch all Users of a demo user company
export const fetchDemoUsers = (id) => async dispatch => {
  try {
    const res = await axios.post(`/api/DemoUserList/${id}`);

    dispatch({ type: FETCH_DEMOUSERS, payload: res.data });
  } catch (error) {
    toastr.error("Oops", "Something went wrong.Please Try Again");
  }
};

//fetch single User
export const fetchUser = id => async dispatch => {
  try {
    const res = await axios.get(`/api/UserDetail/${id}`);

    dispatch({ type: FETCH_USERDETAIL, payload: res.data });
  } catch (error) {
    toastr.error("Oops", "Something went wrong.Please Try Again");
  }
};

//fetch single demo User
export const fetchDemoUser = id => async dispatch => {
  try {
    const res = await axios.get(`/api/DemoUserDetail/${id}`);

    dispatch({ type: FETCH_DEMOUSERDETAIL, payload: res.data });
  } catch (error) {
    toastr.error("Oops", "Something went wrong.Please Try Again");
  }
};
//*************************************************
export const getUserForAuth = () => async dispatch => {
  try {
    const res = await axios.get(`/api/GetUserForAuth`);


    dispatch({ type: FETCH_USER, payload: res.data });
  } catch (error) {
    //toastr.error("Oops", "Something went wrong.Please Try Again");

  }
};
//********************************************
//delete User
export const deleteUser = (id, history) => async () => {
  try {
     await axios.delete(`/api/UserDelete/${id}`);

    history.push("/company/UserList");
  } catch (error) {
    toastr.error("Oops", "Something went wrong.Please Try Again");
  }
};
//****************************
////update User by admin user
export const updateUser = (id, values, history) => async () => {
  try {
    const res = await axios.put(`/api/UserEdit/${id}`, values);

    if (res.data.msg==='success') {

      toastr.success('User Updated Successfully');

    }
    history.push(`/user/UserDetail/${id}`);
  } catch (error) {
    toastr.error("Oops", "Something went wrong.Please Try Again");
  }
};

////update DEMO User by admin user
export const updateDemoUser = (id, values, history) => async () => {
  try {
    const res = await axios.put(`/api/DemoUserEdit/${id}`, values);

    if (res.data.msg==='success') {

      toastr.success('User Updated Successfully');

    }
    history.push(`/companyD/UserDetail/${id}`);
  } catch (error) {
    toastr.error("Oops", "Something went wrong.Please Try Again");
  }
};

//#####################################################
//update User to INACTIVE
export const updateUserToInactive = (id, history) => async dispatch => {
  try {
    const res = await axios.put(`/api/UserInactive/${id}`);


    dispatch({ type: UPDATE_USER, payload: res.data });
    history.push(`/admin/UserListA`);
  } catch (error) {
    toastr.error("Oops", "Something went wrong.Please Try Again");
  }
};


// create Sub User
export const createSUser = (id, values) => async () => {
  try {
    const res = await axios.post("/api/SUserCreate", { id, values });

    if(res.data.msg === 'error'){
      toastr.error("Id Already Exist .")
    }else {
      if(res.data.msg ==='success'){
        toastr.success('User Created')
      }
    }
    //  history.push('/admin/UserListA');
    //dispatch({ type: CREATE_USERS, payload: res.data });

  } catch (error) {

    toastr.error("Oops", "Something went wrong.Please Try Again");
  }
};
//fetch all Users Sale
export const fetchUsersSale = () => async dispatch => {
  try {
    const res = await axios.post(`/api/UserListSale`);

    dispatch({ type: FETCH_USERS, payload: res.data });
  } catch (error) {
    toastr.error("Oops", "Something went wrong.Please Try Again");
  }
};
// create UserSale
export const createUserSale = (values) => async () => {
  try {
    const res = await axios.post("/api/UserCreateSale", {  values });

    if(res.data.msg === 'error'){ 
      toastr.error("Id Already Exist .")
    }else {
      if(res.data.msg ==='success'){
        toastr.success('User Created')
      }
    }
    //  history.push('/admin/UserListA');
    //dispatch({ type: CREATE_USERS, payload: res.data });

  } catch (error) {

    toastr.error("Oops", "Something went wrong.Please Try Again");
  }
};


//********************************** */
export const showSidebar = () => async dispatch => {
  try {

    dispatch({ type: SHOW_SIDEBAR, payload: true });
  } catch (error) {
    toastr.error("Oops", "Something went wrong.Please Try Again");
  }
};

export const hideSidebar = () => async dispatch => {
  try {

    dispatch({ type: HIDE_SIDEBAR, payload: false });
  } catch (error) {
    toastr.error("Oops", "Something went wrong.Please Try Again");
  }
};

//********************************** */

export const showBackButton = () => async dispatch => {
  try {

    dispatch({ type: SHOW_BACKBUTTON, payload: true });
  } catch (error) {
    toastr.error("Oops", "Something went wrong.Please Try Again");
  }
};

export const hideBackButton = () => async dispatch => {
  try {

    dispatch({ type: HIDE_BACKBUTTON, payload: false });
  } catch (error) {
    toastr.error("Oops", "Something went wrong.Please Try Again");
  }
};

//******************** */
//reset state
export const resetState = () => async dispatch => {
  try {


    dispatch({ type: FETCH_FINANCE, payload: null });
    dispatch({ type: FETCH_ACCOUNTTYPE, payload: null });
    dispatch({ type: FETCH_ACCOUNTNAME, payload: null });
    dispatch({ type: FETCH_TASK, payload: null });

  } catch (error) {
    toastr.error("Oops", "Something went wrong.Please Try Again");
  }
};

//^^^^^^^^^^^^^^^^^^^^^^^^^^^
//reset

//reset user
export const resetUser = () => async dispatch => {
  try {
dispatch({ type: FETCH_USERDETAIL, payload: null });

  } catch (error) {
    toastr.error("Oops", "Something went wrong.Please Try Again");
  }
};
