import React, { Component } from 'react';

import {
  Icon,
  Button,
} from 'semantic-ui-react';

var ldis = {
  //display: 'inline',
  //background: '#eee',
  marginTop: '10px',
  padding: '5px',
  color: 'red',
  textAlign: 'center'
};

var spanS = {
  margin: '5px',
  padding: '5px',
  color: 'blue',
  border: '1px solid orange',
  borderRadius: '5px'
};
class Paginator extends Component {


  left= () => {
    // const {
    //   offset,
    //   limit,
    //   count
    //   // values
    // } = this.props;

    return (
      <Button
        color="teal"
        icon
        onClick={this.props.back}
        className={this.props.offset === 0 ? 'disabled' : ''}
      >
        <Icon className='color-black' name="angle left" />
      </Button>
    );
  }
  //{(offset === 0) ? 'disabled' : ''}
  right=() => {
    const { offset, limit, count } = this.props;

    const end = offset + limit >= count ? true : false;


    return (
      <Button
        color="teal"
        icon
        onClick={this.props.advance}
        className={end ? 'disabled' : ''}
      >
        <Icon className='color-black' name="angle right" />
      </Button>
    );
  }

  render() {
    return (
      <div style={ldis}>
        {this.left()}
        <span style={spanS}>
          Page {this.props.offset / this.props.limit + 1}
        </span>
        {this.right()}

        <span className='nodisplay' style={spanS}>Last Page {Math.ceil((this.props.count/this.props.limit)) }  </span>
        <div className='nodisplay-tab' style={{marginTop:'10px'}}>
        <span style={spanS}>Last Page {Math.ceil((this.props.count/this.props.limit)) }  </span>
        </div>

      </div>
    );
  }
}


export default Paginator;
