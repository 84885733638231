
import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { logoutUser,clearStorage,showSidebar,hideSidebar,resetState ,getUserForAuth } from "../../actions/authAction";
import { openModal } from "../../actions/modalAction";

import {   Image, Dropdown ,
 Button,
  Icon,
  } from "semantic-ui-react";
//import {imageURL} from "../configC/keys";


class Navbar extends Component {
  
  componentDidMount() {
    if (!this.props.state.auth.userA){
      this.props.getUserForAuth();
        }

  }

  

  onLogoutClick = () => {

    this.props.logoutUser();

  };
  //*******************************************
  handleSidebarToggle=()=>{
  this.props.showSidebar();
  window.scroll(0,0)
  }

  //*************** */
  backButtonHandler =()=>{
    this.props.history.goBack()
    this.props.resetState()
  }

  
 // *****************************************

  render() {
    //const { open,  } = this.state;
    const { isAuthenticated, user } = this.props.auth;
    //const userA= this.props.userA !== undefined && this.props.userA !== null && this.props.userA
    const renderRefreshButton =()=>{
      return  (
        <Button
        circular
        icon='refresh'
        onClick={() => window.location.reload(false)}
        //onClick={this.handleOpen}
        style={{backgroundColor: 'dodgerblue', color:'white'}}
      />
      ) }
    const renderBackButton =()=>{
      return this.props.visibleBB=== true ? (
      <button

      onClick={this.backButtonHandler}

      className='back-btn'
      >
      <Icon name="arrow left" />

      </button>
      ) : (
      <div></div>
      )}
      ///$$$$$$$$$$$$$$$$$$$$$$$$$$$$$ Activity/Profile Popup
          // const renderShowHideSidebarButton =()=>{
          //   if(((userA.userType === 'admin')||(userA.userType === 'executive'))){
          //   return this.props.visible=== true ? (
          //     <button className='btn'  onClick={this.props.hideSidebar}

          //     ><Icon name="close" /></button>
          //   ) : (
          //     <button  className='btn' onClick={this.handleSidebarToggle }

          //     ><Icon name="sidebar" /></button>
          //     )
          //   }
          //   }
          //########################################

    const authLinks = (
      <div>





<div>{/* nav mobile start*/}
<nav id="navbar-top" className='border-bottom-nav' style={{backgroundColor:'white',flexDirection:'row'}}>



<div style={{display:'flex'}}>

<div style={{display:'flex',alignItems:'center'}}><Image size='mini' src='/logo_pp2p.png' style={{ marginRight: '.5em' }} />
<div className='nodisplay' style={{fontSize:'1.2rem',fontWeight:'bold'}}>
    <span style={{color:'blue'}}>USER.</span>
    <span style={{color:'skyblue'}}>P</span>
    <span style={{color:'dodgerblue'}}>P</span>
    <span style={{color:'orange'}}>2</span>
    <span style={{color:'dodgerblue'}}>P</span>
    <span style={{color:'blue'}}>.IN</span>
  </div>
</div>


{/* <div>FindRealEstate</div> */}

</div>


<ul>

<li>
{renderRefreshButton()}
  </li>



<li>{renderBackButton()}</li>
{/*<li>{renderShowHideSidebarButton()}</li>*/}
<li>
   <Link to={'/'} >

<div style={{fontSize:'2.0rem',fontWeight:'bold',marginRight:'10px',padding:'5px'}}><Icon name='home' /></div>
</Link>

</li>


<li style={{fontSize:'1.5rem',fontWeight:'bold',marginRight:'10px',padding:'5px',color:'blue'}}>

<Dropdown
pointing="top right"
//text={user.name}
icon='user outline'
>
  <Dropdown.Menu>
  <Dropdown.Item
    header='true'

    //text="Sign Out"
   // icon="power"
  ><span style={{color:'blue'}}>{user.name}{' '}</span></Dropdown.Item>
  <Dropdown.Item
    onClick={this.onLogoutClick}
    text="Sign Out"
    icon="power"
  />
    <Dropdown.Item 
      as={Link}
      to="/auth/passchange"
      text="Change Password"
      icon="settings"
    />
    {/* <Dropdown.Item
      onClick={this.show}
      text="Clear Storage"
      icon="trash"
    /> */}
  </Dropdown.Menu>

</Dropdown>

{/*<button className='btn' style={{fontSize:'1.2rem',fontWeight:'bold'}}  onClick={this.handleOpenPop}

><Icon name="user outline" /></button>

 <div style={{fontSize:'1.2rem',fontWeight:'bold'}}><Icon name='user outline' />Profile|Activity</div>
*/}


</li>



</ul>

      </nav>

</div>{/* nav mobile end*/}
      </div>
    );
    const guestLinks = (

      <div >
      <nav id="navbar" className="mastheadNAV">
      <div style={{display:'flex',alignItems:'center',marginLeft:'10px'}}>
        <div className = ' border-logo nodisplay372' style={{ marginRight: '.5em' }} >
        <Image  size='mini' src='/logo_pp2p.png' /></div>
<div className='nodisplay' style={{fontSize:'1.2rem',fontWeight:'bold'}}>
      <span style={{color:'aqua'}}>USER.</span>
      <span style={{color:'pink'}}>P</span>
      <span style={{color:'white'}}>P</span>
      <span style={{color:'orange'}}>2</span>
      <span style={{color:'white'}}>P</span>
      <span style={{color:'aqua'}}>.IN</span>
</div>
</div>
          <ul>
            <li><b><a href="https://user.pp2p.in">Home</a></b></li>

            

          </ul>
        </nav>

      </div>
    );



    return (
      <div>
        {isAuthenticated ? (
          authLinks
        ) : (
          guestLinks
        )}
      </div>
    );
  }
}
//@@@@@@@@@@@@@@@@@

Navbar.propTypes = {
  logoutUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  return {
    state,
  auth: state.auth,
  visible: state.auth.visible,
  visibleBB: state.auth.visibleBB,
  userA: state.auth.userA,
}
};

export default connect(
  mapStateToProps,
  { logoutUser,clearStorage, openModal,showSidebar,hideSidebar ,resetState ,getUserForAuth }
)(withRouter(Navbar));
