import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import ReduxToastr from 'react-redux-toastr';
import store from './store';
import 'semantic-ui-css/semantic.min.css';
import 'react-redux-toastr/lib/css/react-redux-toastr.min.css';
import './index.css';
import App from './components/App';

ReactDOM.render(
  <Provider store={store}>
    <div>
      <ReduxToastr
        position="top-left"
        transitionIn="fadeIn"
        transitionOut="fadeOut"
      />
      <App />
    </div>
  </Provider>,
  document.querySelector('#root')
);
