import React, {Component} from 'react';

import { connect } from "react-redux";
import { hideBackButton,getUserForAuth,logoutUser } from "../../actions/authAction";
import { Link } from 'react-router-dom';
import './css/border.css';
import './css/button.css';
import './css/card.css';
import './css/ribbon.css';
import './css/ribbon2.css';
import './css/common.css';
import './css/color.css';
import './css/container.css';
import './css/display.css';
import './css/grid.css';
import './css/home.css';
import './css/mis.css';
import './css/nav.css';
import './css/sidebar.css';
// import './style.css';
// import './styleFRE.css';
// import './styleABOUTfre.css';
 import './styleK.css';


import { openModal } from "../../actions/modalAction";
//import Layout from "../layout/Layout";
import { 
  //Button,  
  Icon, 
  Image } from 'semantic-ui-react'


class Landing extends Component {
  
  componentDidMount() {
    
    if(this.props.history.location.pathname ==='/'){
      this.props.hideBackButton()
    }

  }
  onLogoutClick = e => {
    e.preventDefault();
    this.props.logoutUser();
  };
  



//****************** */
handleLoginModal = () => {
  this.props.openModal('LoginModal');
};

//*************** */

render() {

  const { isAuthenticated,  } = this.props.auth;
const userA= this.props.userA !== undefined && this.props.userA !== null && this.props.userA
  const able = 'sidebar-border-divider home-div-link'
  // const disable = 'sidebar-border-divider home-div-link-disabled'


      const companyRouteHandler = ()=>{
        // const moduleAbleDisable = (this.props.auth.userA !== null && this.props.auth.userA.note_contactModule === "Yes"  )?
        //    able:disable
        return (
          <div>
          

          <div className={able}> 
          <Link to="/user/UserList" >
          <b>All User </b><span className='icon-angle' ><Icon name="angle right" /></span>
          </Link>
          </div>
          <div className={able}> 
          <Link to="/user/UserCreate" >
          <b>Create User </b><span className='icon-angle' ><Icon name="angle right" /></span>
          </Link>
          </div>

          </div>
              )
      }







const authHome = (
  <div className="background-home  body " style={{marginTop:'50px'}}>
  <div  style={{maxWidth:'700px',margin:'auto',textAlign:'left'}}>

  <div style={{display:'flex', justifyContent:'center', padding:'10px'}}>
<div className='card-home  background-home-link  '>{/* start card*/}


    {companyRouteHandler()}



</div>
</div>  {/* end card/segment*/}

  </div>
  </div>

)



const guestHome = (
  <div style={{marginTop:'50px'}}>


    
    <div id="mainP">
{/* Header: Showcase */}

<section id="showcase" className="masthead ">
  <div className="showcase-content" >
  
  { this.props.auth !== undefined && !this.props.auth.isAuthenticated &&  (
               
               
               <div style={{display:'flex'}}>
               <div
         
         
                 onClick={this.handleLoginModal}
                 className="btnlogin pxy-5-10"
               >
               login
                 
               </div>
               
               </div>
               
               )
               }
  </div>

</section>







</div>


{/* Footer */}
<footer id="main-footer" className="bg-dark text-center py-1">
<div className="containerP">
<div className="row" style={{border:'none'}}>
<div className="disflex-nav-footer" style={{ display: 'flex',flex: '50%',padding: 20,flexDirection:'column'}}>



  <div className = ' border-logo '><Image size='mini' src='/logo_pp2p.png'  /></div>
  {/*  */}
  <div className='' style={{fontSize:'1.2rem',fontWeight:'bold'}}>
  <span style={{color:'aqua'}}>USER.</span>
  <span style={{color:'pink'}}>P</span>
  <span style={{color:'white'}}>P</span>
  <span style={{color:'orange'}}>2</span>
  <span style={{color:'white'}}>P</span>
  <span style={{color:'aqua'}}>.IN</span>
  </div>


{/*  */}
</div>





</div>
<p > © Copyright 2020 CREDIBLE ITSYS, All Rights Reserved</p>
<br/>
</div>
</footer>


{/*  */}
  </div>
);
const renderRoute =()=>{
  if(isAuthenticated && ((userA.userType === 'admin')||(userA.userType === 'executive'))){
    return authHome
  }

  if(!isAuthenticated){
    return guestHome
  }
}
  return(
    <div>
      {renderRoute()}
    </div>
  );
}
}
const mapStateToProps = state => ({
  auth: state.auth,
  visible: state.auth.visible,
  visibleBB: state.auth.visibleBB,
  userA: state.auth.userA,
});

export default connect(
  mapStateToProps,
  {openModal, logoutUser,hideBackButton,getUserForAuth  }
)(Landing);
//export default Landing;
