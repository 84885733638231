
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
//import format from "date-fns/format";
import Paginator from "../paginator/Paginator";
import {searchUsers} from "../../actions/authAction";
import { openModal } from "../../actions/modalAction";
import {
  Image,
  Container,
  Divider,
  Segment,
  Button,
  Icon,
} from "semantic-ui-react";



class UserList extends Component {


  componentDidMount() {
    const values =
    (this.props.form !== undefined && this.props.form.values) || {};
    this.props.searchUsers(values);
  }

  handleSearchUser = () => {
    this.props.openModal("SearchUserModal");
  };
  handleOpenModal = (url) => {   
    this.props.openModal("ImageZoomModal" , {imageURL:url});
  };
  renderList=(user)=> {
    const { 
      uimage,
      uname,
      uphone1,
      city,
       _id,
       //createdAt
      } = user;
    return (
      <Segment key={_id}>

<Image
    className="icon2-button "
    onClick={() => this.handleOpenModal(uimage)}
    style={{maxWidth:"50px", maxHeight:"50px",}}
      floated="left"
      size="tiny"
      src={`${uimage}`}
    />
        <div >
          <Button
          // basic
          // inverted
          floated="right"
          size="mini"
          color="teal"
          type="button"
          as={Link}
          //target='_blank'
           to={`/user/UserDetail/${_id}`}
        >
          Detail
        </Button>
        </div>

        <br />
        <br />
        <Divider fitted />
        <div >
        {uname && (
        <div className='rowERP'>
        <div className='sideERP'>
        <b>User Name</b>
        </div>
        <div className='mainERP'>
        <b>{uname}</b>
        </div>

        </div>
        )}
        </div>
        <div >
        {uphone1 && (
        <div className='row'>
        <div className='side'>
        <b>User Phone1</b>
        </div>
        <div className='main'>
        <b>{uphone1}</b>
        </div>

        </div>
        )}
        </div>
        
        <div >
        {city && (
        <div className='rowERP'>
        <div className='sideERP'>
        <b>City</b>
        </div>
        <div className='mainERP'>
        <b>{city}</b>
        </div>

        </div>
        )}
        </div>

                  

      </Segment>
    );
  }

  back = () => {
    const {
      offset,
      limit

    } = this.props;
    const values =
    (this.props.form !== undefined && this.props.form.values) || {};

    if (offset === 0) {
      return;
    }

    this.props.searchUsers(values,limit, (offset - limit));
  };

  advance = () => {
    const {
      offset,
      limit,
      count

    } = this.props;
const values =
      (this.props.form !== undefined && this.props.form.values) || {};

    if (offset + limit > count) {
      return;
    }

    this.props.searchUsers(values,limit, (offset + limit));
  };

  renderPaginator() {
    if (this.props.users.length) {
      return (
        <Paginator
          advance={this.advance}
          back={this.back}
          offset={this.props.offset}
          limit={this.props.limit}
          count={this.props.count}
        />
      );
    }
  }


  render() {
    if (
      this.props.userA !== null &&
      (this.props.userA.userType === "admin")
    ) {
    return (
      <Container text>

      {/**/}
<div className='cashbook-H'>

<div  style={{display: 'flex',flex: '90%',justifyContent: 'center',}} >
<div   style={{display: 'flex',justifyContent: 'center',fontSize:'1.3rem',fontWeight:'bold',padding:'5px'}}  >
{this.props.count}{' '}All Users
</div>
</div>

<div
style={{display: 'flex',flex: '10%',justifyContent: 'center',alignItems: 'center',}}
onClick={this.handleSearchUser}>
    <span className='icon-button border1' > <Icon name='search' /></span >
  </div>


</div>
{/**/}


        {this.props.users.map(this.renderList)}

        {/*{this.renderPaginator()}*/}
      </Container>
    );
  }
  return (
    <Container text>
      <Segment>
        <div style={{ color: "red", textAlign: "center" }}>
          <h2>You Are Not Authorised To View Users </h2>
        </div>
      </Segment>
    </Container>
  );
  }
  }


const mapStateToProps = ({ auth, form }) => {
  const { limit, offset, count } = auth;
 
  return {
    limit,
    offset,
    count,
    form:form.UserSearch,
    userA: auth.userA,
    users: auth.usersA,
  };
};

export default connect(
  mapStateToProps,
  {searchUsers,openModal}
)(UserList);
