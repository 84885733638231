import React, { Component } from "react";
import { connect } from "react-redux";
import { Container  } from "semantic-ui-react";
import { Switch, Route,Link  } from "react-router-dom";

import { showBackButton,getUserForAuth,logoutUser } from "../../actions/authAction";
import ScrollTopButton from "../scroll/ScrollTopButton";
import ScrollBottomButton from "../scroll/ScrollBottomButton";
 

import AddImage from "./AddImage";

import UserDetail from "./UserDetail";
import UserCreate from "./UserCreate";
import UserEdit from "./UserEdit";
import UserList from "./UserList";


import Layout from "../layout/Layout";



  class UserDashboard extends Component {
    state = {
      openPop: false,
    };
    componentDidMount() {
      this.props.getUserForAuth();
      if(this.props.history.location.pathname !=='/'){
        this.props.showBackButton()
      }
    }
  onLogoutClick = e => {
    e.preventDefault();
    this.props.logoutUser();
  };
  //****************************** */
  handleOpenPop = () => {this.setState({ openPop: true }); window.scroll(0,0)}
    handleClosePop = () => this.setState({ openPop: false })
    //********************** */
  render(){
    const { isAuthenticated,  } = this.props.auth;

    const authLinks = (
      <div>

                <div>
                <div className='maindiv'>



      <Layout onLogoutClick={this.onLogoutClick}>
      <Container text>
        <div className='margin-auto' >
          <div   style={{fontSize:'1.2rem',fontWeight:'bold',padding:'5px'}}  >

          {/*  */}
       <div className='display-center'>
       <div className="btnnav" >
       <Link  to="/user/UserList" >
       All Users
     </Link>
       </div>
       <div className="btnnav" >
       <Link to="/user/UserCreate" >
       Create User
     </Link>
       </div>


     </div>
       {/*  */}


       </div>
       </div>
       </Container>  
       {/*         */}

          <Switch>
          <Route exact path="/user/addimage/:id" component={AddImage} />
          
          <Route exact path="/user/UserList" component={UserList} />
          <Route exact path="/user/UserDetail/:id" component={UserDetail} />
          <Route exact path="/user/UserEdit/:id" component={UserEdit} />
          <Route exact path="/user/UserCreate" component={UserCreate} />
          
          
          
          </Switch>
          </Layout >
          </div>

  <div >
  <ScrollBottomButton scrollStepInPx="11550" delayInMs="16.66"/>

  <ScrollTopButton scrollStepInPx="11000" delayInMs="16.66"/>
  </div>

  </div>
      </div>
    );

  return (
    <div>
      {isAuthenticated ? (
        authLinks
      ) : (
        <div></div>
      )}
    </div>
  );
}
}
const mapStateToProps = state => ({
auth: state.auth
});

export default connect(
mapStateToProps,
  {showBackButton, getUserForAuth, logoutUser }
)(UserDashboard);
