import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import format from "date-fns/format";
import {fetchUser,resetUser} from "../../actions/authAction";
import { openModal } from "../../actions/modalAction";
import {

  Container,
  Segment,
  Button,
 // Header,
  Confirm
} from "semantic-ui-react";




class UserDetail extends Component {
  state = {
    id: "",
    open: false,
    result: "show the modal to capture a result"
  };

  componentDidMount() {
    const { id } = this.props.match.params;
    this.props.fetchUser(id);
  }
  handleOpenModal = (url) => {   
    this.props.openModal("ImageZoomModal" , {imageURL:url});
  };
  // show = id => {
  
  //   this.setState({ open: true, id: id });
  // };
  // handleConfirm = () => {
  //   const did = this.state.id;
  //   const values = this.state;
  //   this.setState({ result: "confirmed", open: false });
  
  //   this.props.deleteUser(did, this.props.history);
  //   this.props.fetchUsers(values);
  // };
  // handleCancel = () => this.setState({ result: "cancelled", open: false });
  componentWillUnmount() {
      this.props.resetUser();
    }
  render() {

    const {
      uimage,
      userId, uphone1, uphone2, uwhatsapp,
      uname,
      uemail,
      city,
      _id,
      userType,
      userStatus,
      userSubscriptionStartOn,
      userSubscriptionExpireOn,
      //createdAt,
      
    } =
      this.props.user !== undefined &&
      this.props.user !== null &&
      this.props.user;

//********************
    const { open, } = this.state;
    if( this.props.userA !== null &&
      (this.props.userA.userType === "admin" || this.props.userA.userType === "executive")  ){
    return (
      <Container text>
        <Confirm
          // header={_id}
          size="mini"
          open={open}
          onCancel={this.handleCancel}
          onConfirm={() => this.handleConfirm()}
        />
        {/**/}
        <div className='cashbook-H'>

<div  style={{display: 'flex',flex: '90%',justifyContent: 'center',}} >
<div   style={{display: 'flex',justifyContent: 'center',fontSize:'1.3rem',fontWeight:'bold',padding:'5px'}}  >
  User Detail
</div>
</div>

{/*<div
style={{display: 'flex',flex: '10%',justifyContent: 'center',alignItems: 'center',}}
onClick={this.handleSearchSingleAccountFinance}>
      <span className='icon-button border1' > <Icon name='search' />Search</span >
    </div>*/}


</div>
{/**/}
        {/*<Segment
          textAlign="center"
          attached="top"
          inverted
          color="teal"
          style={{ border: "none" }}
        >
          <Header> User Detail</Header>
        </Segment>*/}
        <Segment attached>
          <Button.Group size="mini" floated="right">


            <div ><Button
              // basic
              // inverted
              floated="right"
              size="mini"
              color="teal"
              type="button"
              as={Link}
              //target='_blank'
              to={`/user/UserEdit/${_id}`}
            >
              Edit
            </Button>
            <Button
              // basic
              // inverted
              floated="right"
              size="mini"
              color="blue"
              type="button"
              as={Link}
              //target='_blank'
              to={`/auth/changepass/${_id}`}
            >
              Change Pass
            </Button></div>
            <Button
              // basic
              inverted
              floated="right"
              size="mini"
              color="orange"
              type="button"
              as={Link}
              to={`/user/addimage/${_id}`}
            >
              Add Image
            </Button>
          </Button.Group>
          <br />
          <br />
          {/*  */}
          <div className="disflex-paginator">
          <img 
              className="icon2-button "
              onClick={() => this.handleOpenModal(uimage)}
              src={`${uimage}`} alt="profile image" 
              style={{width:"100px", height:"100px",}} />
          
          </div>
{/* */} 
          <div >
        {uname && (
        <div className='rowERP'>
        <div className='sideERP'>
        <b>User Name</b>
        </div>
        <div className='mainERP'>
        <b>{uname}</b>
        </div>

        </div>
        )}
        </div>

        <div >
          {userId && (
        <div className='row'>
        <div className='side'>
        <b>User Id</b>
        </div>
        <div className='main'>
        <b>{userId}</b>
        </div>

        </div>
        )}
        </div>
        <div >
        {uphone1 && (
        <div className='row'>
        <div className='side'>
        <b>User Phone1</b>
        </div>
        <div className='main'>
        <b>{uphone1}</b>
        </div>

        </div>
        )}
        </div>
        <div >
        {uphone2 && (
        <div className='row'>
        <div className='side'>
        <b>User Phone2</b>
        </div>
        <div className='main'>
        <b>{uphone2}</b>
        </div>

        </div>
        )}
        </div>
        <div >
        {uwhatsapp && (
        <div className='row'>
        <div className='side'>
        <b>Whatsapp Id</b>
        </div>
        <div className='main'>
        <b>{uwhatsapp}</b>
        </div>

        </div>
        )}
        </div>

        <div >
        {uemail && (
        <div className='rowERP'>
        <div className='sideERP'>
        <b>User Email</b>
        </div>
        <div className='mainERP'>
        <b>{uemail}</b>
        </div>

        </div>
        )}
        </div>
        
        <div >
        {city && (
        <div className='rowERP'>
        <div className='sideERP'>
        <b>City</b>
        </div>
        <div className='mainERP'>
        <b>{city}</b>
        </div>

        </div>
        )}
        </div>

        <div >
        {userType && (
        <div className='rowERP'>
        <div className='sideERP'>
        <b>User Type</b>
        </div>
        <div className='mainERP'>
        <b>{userType}</b>
        </div>

        </div>
        )}
        </div>
        <div >
        {userStatus && (
        <div className='rowERP'>
        <div className='sideERP'>
        <b>User Status</b>
        </div>
        <div className='mainERP'>
        <b>{userStatus}</b>
        </div>

        </div>
        )}
        </div>
                  <div >
                  {userSubscriptionStartOn && (
                  <div className='rowERP'>
                  <div className='sideERP'>
                  <b>Subscription Start On</b>
                  </div>
                  <div className='mainERP'>
                  <b>{format(new Date(userSubscriptionStartOn), "MMM d yyyy")}</b>{' '}

                  </div>

                  </div>
                  )}
                  </div>

                  <div >
                  {userSubscriptionExpireOn && (
                  <div className='rowERP'>
                  <div className='sideERP'>
                  <b>Subscription Expire On</b>
                  </div>
                  <div className='mainERP'>
                  <b>{format(new Date(userSubscriptionExpireOn), "MMM d yyyy")}</b>{' '}

                  </div>

                  </div>
                  )}
                  </div>


          {/* end  display none for desktop */}
        </Segment>
      </Container>
    );
  }
  return (
    <Container text>
      <Segment>
        <div style={{ color: "red", textAlign: "center" }}>
          <h2>You Are Not Authorised To View User Details </h2>
        </div>
      </Segment>
    </Container>
  );
  }
}


const mapStateToProps = ({ auth }) => {

  return {
    user: auth.userD,
    userA:auth.userA
  };
};

export default connect(
  mapStateToProps,
  {fetchUser,resetUser,openModal}
)(UserDetail);
