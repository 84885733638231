import React from 'react';
import { connect } from 'react-redux';
import { reduxForm, Field } from 'redux-form';

import { closeModal } from "../../actions/modalAction";
import {searchUsers} from "../../actions/authAction";
import { withRouter } from 'react-router-dom';

import {
  Divider,
  //Segment,
  Form,
  Button,
  Header
} from 'semantic-ui-react';
import TextInput from '../form/TextInput';
import SelectInput from '../form/SelectInput';
//import DateInput from '../form/DateInput';

import { userStatus  } from './Data';
const UserSearch = props => {
  const { handleSubmit, pristine, reset, submitting,  } = props;

  const onSubmit = values => {

    props.searchUsers(values);
    //this.props.closeModal();
  };
  return (
    <div style={{maxWidth:'700px',margin:'auto'}}>

        {/**/}
        <div className='margin-auto-H border-radius-3' >
        <div   style={{fontSize:'1.3rem',fontWeight:'bold',padding:'5px'}}  >
          {/*  */}
          Search Users
          {/*  */}
       </div>
       </div>
       {/**/}
        <div className="card-form ">
          <div className=" label" >

          <Form style={{ padding: 25 }} onSubmit={handleSubmit(onSubmit)}>
          <Button
            color="teal"
            size="mini"
            type="button"
            onClick={()=>props.closeModal()}

          >
            Cancel
          </Button>
        <Button
          size="mini"
          color="orange"
          type="button"
          disabled={pristine || submitting}
          onClick={reset}
        >
          Clear Values
        </Button>
          <br /><br />

            
          
            <label >Name / Mobile / userId Of User </label>
            <Field
              name="name"
              type="text"
              component={TextInput}
              placeholder="Name Of User"
            />
            
            <label htmlFor="">userStatus</label>
                <Field
                //search={true}
                name="userStatus"
                type="text"
                component={SelectInput}
                options={userStatus()}
                placeholder="userStatus"
                />
                

            <br />

            <Button
              color="blue"
              type="submit"
             // disabled={pristine || submitting}
            >
              Search
            </Button>
            <Button
              color="orange"
              type="button"
              disabled={pristine || submitting}
              onClick={reset}
            >
              Clear Values
            </Button>
          </Form>
          <br/><br/><br/>
            </div>
            </div>
            </div>
  );
};

export default connect(
  null,
  { searchUsers, closeModal }
)(
  reduxForm({
    form: 'UserSearch', // a unique identifier for this form
    destroyOnUnmount: false

    //validate
  })(withRouter(UserSearch))
);
