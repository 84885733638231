import React, { Component } from "react";
import { connect } from "react-redux";
import { reduxForm, Field } from "redux-form";


import {fetchUser,updateUser} from "../../actions/authAction";
import { withRouter } from "react-router-dom";

import { Segment, Form, Button,   Container } from "semantic-ui-react";
import TextInput from "../form/TextInput";
import DateInput from '../form/DateInput';
import SelectInput from "../form/SelectInput";



const userTypeA = ["saleTeam"];
const userType = () => {
  return userTypeA.map(value => ({
    key: value,
    text: value,
    value: value
  }));
};


// const optionsA = ["Yes", "No"];
// const options = () => {
//   return optionsA.map(value => ({
//     key: value,
//     text: value,
//     value: value
//   }));
// };
const userStatusA = ["active", "inactive"];
const userStatus = () => {
  return userStatusA.map(value => ({
    key: value,
    text: value,
    value: value
  }));
};


class UserEdit extends Component {
  componentDidMount() {
    const { id } = this.props.match.params;
    this.props.fetchUser(id);
  }

  onSubmit = values => {
    const { id } = this.props.match.params;
    this.props.updateUser(id, values, this.props.history);
    this.props.history.goBack()
  };
  backButtonHandler =()=>{
    this.props.history.goBack()
  }

  render() {
    const { handleSubmit, pristine, reset, submitting } = this.props;
    if( this.props.userA !== null &&
      this.props.userA.userType === "admin" ){
    return (
      <div style={{maxWidth:'700px',margin:'auto'}}>
      {/**/}
      <div className='margin-auto-H' >
<div   style={{fontSize:'1.3rem',fontWeight:'bold',padding:'5px'}}  >
 {/*  */}
 Edit User
{/*  */}
</div>
</div>
{/**/}

        <div className="card border ">
          <div className=" label" >

            <Form
              style={{ padding: 25 }}
              onSubmit={handleSubmit(this.onSubmit)}
            >

             <Button
            color="teal"
            size="mini"
            type="button"
            onClick={()=>this.backButtonHandler()}

          >
            Cancel
          </Button>
          <br /><br />
          <label >User's Name</label>
              <Field
                name="uname"
                type="text"
                component={TextInput}
                placeholder="User's Name"
              />
              <label >User's Mobile as user ID</label>
              <Field
                name="userId"
                type="text"
                component={TextInput}
                placeholder="User's Mobile as user ID"
              />
              <label >User's Phone1</label>
                <Field
                  name="uphone1"
                  type="text"
                  component={TextInput}
                  placeholder="Phone1"
                />
                <label >User's Phone2</label>
                <Field
                  name="uphone2"
                  type="text"
                  component={TextInput}
                  placeholder="Phone2"
                />

                <label >User's Whatsapp ID</label>
                <Field
                  name="uwhatsapp"
                  type="text"
                  component={TextInput}
                  placeholder="Whatsapp ID"
                />
              <label >User's Email</label>
              <Field
                name="uemail"
                type="email"
                component={TextInput}
                placeholder="User's Email"
              />
              
<label htmlFor="">userStatus</label>
<Field
//search={true}
name="userStatus"
type="text"
component={SelectInput}
options={userStatus()}
placeholder="userStatus"
/>
<label >User Type</label>
<Field
  search={true}
  name="userType"
  type="text"
  component={SelectInput}
  options={userType()}
  placeholder="User Type"
/>
{/*  */}
<label htmlFor="">User Subscription Start On</label>
<Field
  name="userSubscriptionStartOn"
  type="text"
  component={DateInput}
  placeholder="Started On"
  dateFormat="dd-MM-yyyy"
  peekNextMonth
  showMonthDropdown
  showYearDropdown
  dropdownMode="select"
/>
<label htmlFor="">User Subscription Expire On</label>
<Field
  name="userSubscriptionExpireOn"
  type="text"
  component={DateInput}
  placeholder="Expired On"
  dateFormat="dd-MM-yyyy"
  peekNextMonth
  showMonthDropdown
  showYearDropdown
  dropdownMode="select"
/>

{/*  */}

              <br />

              <Button
                color="olive"
                type="submit"
                disabled={pristine || submitting}
              >
                Submit
              </Button>
              <Button
                color="orange"
                type="button"
                disabled={pristine || submitting}
                onClick={reset}
              >
                Clear Values
              </Button>
            </Form>
            <br/><br/><br/>
            </div>
            </div>
            </div>
    );
  }
  return (
    <Container text>
      <Segment>
        <div style={{ color: "red", textAlign: "center" }}>
          <h2>You Are Not Authorised To Edit User </h2>
        </div>
      </Segment>
    </Container>
  );
  }
}

export default connect(
  state => {
    
    return { initialValues: state.auth.userD,userA: state.auth.userA }; // pull initial values from auth reducer
  },
  {fetchUser,updateUser}
)(
  reduxForm({
    form: "UserEditA" // a unique identifier for this form
    // validate
  })(withRouter(UserEdit))
);
