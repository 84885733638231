import React, { Component } from "react";
import { connect } from 'react-redux';
import { reduxForm, Field } from 'redux-form';

//import Script from 'react-load-script';
import { withRouter } from 'react-router-dom';

import TextInput from '../form/TextInput';
// import TextArea from '../form/TextArea';
// import SelectInput from '../form/SelectInput';
// import DateInput from '../form/DateInput';
// import PlaceInput from '../form/PlaceInput';
import {updatePassword} from '../../actions/authAction';
import { 
  Segment,
  Form,
  Button,
  Header,
  Checkbox,
  Popup,
} from 'semantic-ui-react';




import {
  composeValidators,
  combineValidators,
  isRequired,
} from 'revalidate';
import {isValidPassword} from "../validator/revalidator";
var passwordValidator = require('password-validator');

const validate = combineValidators({
//************user */

  password:composeValidators(
    isRequired('Password'),
    isValidPassword({})

  )(),
  
 // password:isRequired('Password') ,
  password2:isRequired('Confirm Password') ,
  
  
});



// Create a schema
var schema = new passwordValidator();

// Add properties to it
schema
.is().min(8)                                    // Minimum length 8
.is().max(16)                                  // Maximum length 16
.has().uppercase()                              // Must have uppercase letters
.has().lowercase()                              // Must have lowercase letters
.has().digits()                               // Must have digits
.has().symbols()                                // Must have symbols 
.has().not().spaces()                           // Should not have spaces
.is().not().oneOf(['Passw0rd', 'Password123']); // Blacklist these values



class PassChange extends Component {
  state = {
    showP:false,
   
  };

  backButtonHandler =()=>{
    this.props.history.goBack()
  }
  

   onSubmit = values => {
    
    this.props.updatePassword(values, this.props.history);
  };

  toggle = () => this.setState({ showP: !this.state.showP })

  render () {
    const { handleSubmit, pristine, reset, submitting } = this.props;

    const { password,cpassword} = 
      this.props.state.form &&
      this.props.state.form.PassChangeForm !== undefined &&
      this.props.state.form.PassChangeForm.values !== undefined &&
      this.props.state.form.PassChangeForm.values;

    const showPass = this.state.showP ? "text" : "password"
//************** */

const renderMatchPassword =()=>{
  if(password && cpassword){
    
      if(password !== cpassword){
       return (<div style={{ color:'red'}} >
        <b>Password do not match</b> 
       </div>)
      }
    

  }
}

const renderPasswordError = () => {
if(password) return schema.validate(password, { list: true }).map(item => {
return (
<span 
style={{color:'red'}}
key={item}>
{" "}
<b>{item} ,</b>
</span>
);
});
};



    //************ */
  return (
    <div style={{maxWidth:'500px',margin:'auto'}}>
      <Segment
          textAlign="center"
          //attached="bottom"
          inverted
          color="teal"
          style={{ border: "none" }}
        >
        <Header> Change password </Header>
        </Segment>
        

          <div className="card ">
          <div className=" label" >
          <Form style={{ padding: 25 }} onSubmit={handleSubmit(this.onSubmit)}>
          
          <br /><br />
          <Checkbox
              //name="showP"
              //type="checkbox"
              label='Show Password'
              onChange={this.toggle}
              checked={this.state.showP}
              />
<br/><br/>
          <label>Password</label>{' '}{' '}
            <Popup trigger={<span>hint?</span>} wide='very'>
            Password Must have Minimum eight and maximum 16 characters, at least one uppercase letter, one lowercase letter, one number and one special character:
            </Popup>{' '} {renderPasswordError()}
            <Field
            //onBlur=
              name="password"
              type={showPass}
              component={TextInput}
              placeholder="Password"
            /> 
           
            
            <label>Confirm Password</label>{' '}
            <Field
              name="cpassword"
              type={showPass}
              //type="password"
              component={TextInput}
              placeholder="Confirm Password"
            />
            {renderMatchPassword()}

            <br />

                <Button color="teal"  size="mini">
                  Update Password
                </Button>
                <Button
                  color="blue"
                  size="mini"
                  type="button"
                  onClick={()=>this.backButtonHandler()}

                >
                  Cancel
                </Button>
                <Button
                size="mini"
                  color="orange"
                  type="button"
                  disabled={pristine || submitting}
                  onClick={reset}
                >
                  Clear Values
                </Button>
          </Form>
        
          <br/><br/><br/>
            </div>
            </div>
            </div>
  );
}
}

const mapStateToProps = state => {
  return {
    state,
  };
};

export default connect(
  mapStateToProps,
  {updatePassword}
)(
  reduxForm({
    form: 'PassChangeForm', // a unique identifier for this form
     validate
  })(withRouter(PassChange))
);
