
//COMMON action Types
export const TRANSPORT = 'TRANSPORT';
export const MESSAGE = 'MESSAGE';
//companys action Types
export const SEARCH_COMPANYS = 'SEARCH_COMPANYS';
export const CREATE_COMPANYS = 'CREATE_COMPANYS';
export const FETCH_COMPANYS = 'FETCH_COMPANYS';
export const FETCH_COMPANYSBLOCKED = 'FETCH_COMPANYSBLOCKED';
export const FETCH_COMPANYSUNBLOCKED = 'FETCH_COMPANYSUNBLOCKED';
export const FETCH_COMPANY = 'FETCH_COMPANY';
export const DELETE_COMPANY = 'DELETE_COMPANY';
export const UPDATE_COMPANY = 'UPDATE_COMPANY';
export const PROPREQ_COUNT = 'PROPREQ_COUNT';

export const SELECT_COMPANY = 'SELECT_COMPANY';
export const DESELECT_COMPANY = 'DESELECT_COMPANY';
export const FETCHING_COMPANYS = 'FETCHING_COMPANYS';
//##############################################
// companys using demo app action Types
export const SEARCH_DEMOCOMPANYS = 'SEARCH_DEMOCOMPANYS';

export const FETCH_DEMOCOMPANY = 'FETCH_DEMOCOMPANY';

export const FETCHING_DEMOCOMPANYS = 'FETCHING_DEMOCOMPANYS';
//##############################################
export const RESET_SELECTION = 'RESET_SELECTION';
//##############################################


//finance action Types
export const SEARCH_FINANCES = 'SEARCH_FINANCES';
export const CREATE_FINANCES = 'CREATE_FINANCES';
export const FETCH_FINANCES = 'FETCH_FINANCES';
export const FETCH_TODAYFINANCES = 'FETCH_TODAYFINANCES';
export const FETCH_FINANCE = 'FETCH_FINANCE';
export const FETCH_RECEIVABLE_PAYABLE = 'FETCH_RECEIVABLE_PAYABLE';
export const FETCH_RECEIVABLE_PAYABLE_INTEREST = 'FETCH_RECEIVABLE_PAYABLE_INTEREST';

export const DELETE_FINANCE = 'DELETE_FINANCE';
export const UPDATE_FINANCE = 'UPDATE_FINANCE';
export const SELECT_FINANCE = 'SELECT_FINANCE';
export const SELECT_FINANCEALL = 'SELECT_FINANCEALL';
export const DESELECT_FINANCEALL = 'DESELECT_FINANCEALL';
export const DESELECT_FINANCE = 'DESELECT_FINANCE';
export const FETCHING_FINANCES = 'FETCHING_FINANCES';

//image action types
export const SEARCH_IMAGES = 'SEARCH_IMAGES';
export const SELECT_IMAGE = 'SELECT_IMAGE';
export const DESELECT_IMAGE = 'DESELECT_IMAGE';
export const CREATE_IMAGES = 'CREATE_IMAGES';
export const FETCH_IMAGES = 'FETCH_IMAGES';
export const FETCH_IMAGE = 'FETCH_IMAGE';
export const DELETE_IMAGE = 'DELETE_IMAGE';
export const UPDATE_IMAGE = 'UPDATE_IMAGE';
export const FETCHING_IMAGES = 'FETCHING_IMAGES';

//accountType action Types
export const SEARCH_ACCOUNTTYPES = 'SEARCH_ACCOUNTTYPES';
export const CREATE_ACCOUNTTYPES = 'CREATE_ACCOUNTTYPES';
export const FETCH_ACCOUNTTYPES = 'FETCH_ACCOUNTTYPES';
export const FETCH_ACCOUNTTYPE = 'FETCH_ACCOUNTTYPE';
export const DELETE_ACCOUNTTYPE = 'DELETE_ACCOUNTTYPE';
export const UPDATE_ACCOUNTTYPE = 'UPDATE_ACCOUNTTYPE';

//accountName action Types

export const CREATE_ACCOUNTNAMES = 'CREATE_ACCOUNTNAMES';
export const FETCH_ACCOUNTNAMES = 'FETCH_ACCOUNTNAMES';
export const FETCH_ACCOUNTNAME = 'FETCH_ACCOUNTNAME';
export const DELETE_ACCOUNTNAME = 'DELETE_ACCOUNTNAME';
export const UPDATE_ACCOUNTNAME = 'UPDATE_ACCOUNTNAME';
//accountRef action Types

export const CREATE_ACCOUNTREFS = 'CREATE_ACCOUNTREFS';
export const FETCH_ACCOUNTREFS = 'FETCH_ACCOUNTREFS';
export const FETCH_ACCOUNTREF = 'FETCH_ACCOUNTREF';
export const DELETE_ACCOUNTREF = 'DELETE_ACCOUNTREF';
export const UPDATE_ACCOUNTREF = 'UPDATE_ACCOUNTREF';

//NOTE action Types
//export const SEARCH_NOTES = 'SEARCH_NOTES';
export const CREATE_NOTES = 'CREATE_NOTES';
export const FETCH_NOTES = 'FETCH_NOTES';
export const FETCH_NOTE = 'FETCH_NOTE';
export const DELETE_NOTE = 'DELETE_NOTE';
export const UPDATE_NOTE = 'UPDATE_NOTE';
export const FETCHING_NOTES = 'FETCHING_NOTES';
//CONTACT action Types
export const SEARCH_CONTACTS = 'SEARCH_CONTACTS';
export const CREATE_CONTACTS = 'CREATE_CONTACTS';
export const FETCH_CONTACT = 'FETCH_CONTACT';
export const DELETE_CONTACT = 'DELETE_CONTACT';
export const UPDATE_CONTACT = 'UPDATE_CONTACT';
export const FETCHING_CONTACTS = 'FETCHING_CONTACTS';
//BORROWEDITEMS action Types
export const SEARCH_BORROWEDITEMS = 'SEARCH_BORROWEDITEMS';
export const SELECT_BORROWEDITEM = 'SELECT_BORROWEDITEM';
export const DESELECT_BORROWEDITEM = 'DESELECT_BORROWEDITEM';
export const CREATE_BORROWEDITEMS = 'CREATE_BORROWEDITEMS';
export const FETCH_BORROWEDITEMS = 'FETCH_BORROWEDITEMS';
export const FETCH_TODAYBORROWEDITEMS = 'FETCH_TODAYBORROWEDITEMS';
export const FETCH_BORROWEDITEM = 'FETCH_BORROWEDITEM';
export const DELETE_BORROWEDITEM = 'DELETE_BORROWEDITEM';
export const UPDATE_BORROWEDITEM = 'UPDATE_BORROWEDITEM';
export const FETCHING_BORROWEDITEMS = 'FETCHING_BORROWEDITEMS';
//LentItem action Types
export const SEARCH_LENTITEMS = 'SEARCH_LENTITEMS';
export const SELECT_LENTITEM = 'SELECT_LENTITEM';
export const DESELECT_LENTITEM = 'DESELECT_LENTITEM';
export const CREATE_LENTITEMS = 'CREATE_LENTITEMS';
export const FETCH_LENTITEMS = 'FETCH_LENTITEMS';
export const FETCH_TODAYLENTITEMS = 'FETCH_TODAYLENTITEMS';
export const FETCH_LENTITEM = 'FETCH_LENTITEM';
export const DELETE_LENTITEM = 'DELETE_LENTITEM';
export const UPDATE_LENTITEM = 'UPDATE_LENTITEM';
export const FETCHING_LENTITEMS = 'FETCHING_LENTITEMS';
//ITEMLOCATIONS action Types
export const SEARCH_ITEMLOCATIONS = 'SEARCH_ITEMLOCATIONS';
export const SELECT_ITEMLOCATION = 'SELECT_ITEMLOCATION';
export const DESELECT_ITEMLOCATION = 'DESELECT_ITEMLOCATION';
export const CREATE_ITEMLOCATIONS = 'CREATE_ITEMLOCATIONS';
export const FETCH_ITEMLOCATIONS = 'FETCH_ITEMLOCATIONS';
export const FETCH_ITEMLOCATION = 'FETCH_ITEMLOCATION';
export const DELETE_ITEMLOCATION = 'DELETE_ITEMLOCATION';
export const UPDATE_ITEMLOCATION = 'UPDATE_ITEMLOCATION';
export const FETCHING_ITEMLOCATIONS = 'FETCHING_ITEMLOCATIONS';
//task action Types
export const SEARCH_TASKS = 'SEARCH_TASKS';
export const SELECT_TASK = 'SELECT_TASK';
export const DESELECT_TASK = 'DESELECT_TASK';
export const CREATE_TASKS = 'CREATE_TASKS';
export const FETCH_TASKS = 'FETCH_TASKS';
export const FETCH_TODAYTASKS = 'FETCH_TODAYTASKS';
export const FETCH_TASK = 'FETCH_TASK';
export const DELETE_TASK = 'DELETE_TASK';
export const UPDATE_TASK = 'UPDATE_TASK';
export const FETCHING_TASKS = 'FETCHING_TASKS';



export const CREATE_ERROR = 'CREATE_ERROR';
export const CLEAR_ERROR = 'CLEAR_ERROR';

//###################################
export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const GET_ERRORS = 'GET_ERRORS';
//##################################,
// user action types
export const SEARCH_USERS = 'SEARCH_USERS';
export const CREATE_USERS = 'CREATE_USERS';
export const FETCH_USERS = 'FETCH_USERS';
export const FETCH_USER = 'FETCH_USER';
export const FETCH_USERDETAIL = 'FETCH_USERDETAIL';
export const DELETE_USER = 'DELETE_USER';
export const UPDATE_USER = 'UPDATE_USER';

//demo user action Types
export const FETCH_DEMOUSERS = 'FETCH_DEMOUSERS';
export const FETCH_DEMOUSERDETAIL = 'FETCH_DEMOUSERDETAILL';

// modal action types
export const MODAL_OPEN = 'MODAL_OPEN';
export const MODAL_CLOSE = 'MODAL_CLOSE';
export const FETCHING = 'FETCHING';

// sidebar action types
export const SHOW_SIDEBAR = 'SHOW_SIDEBAR';
export const HIDE_SIDEBAR = 'HIDE_SIDEBAR';
// Backbutton action types
export const SHOW_BACKBUTTON = 'SHOW_BACKBUTTON';
export const HIDE_BACKBUTTON = 'HIDE_BACKBUTTON';
