const sortingO = [
  'Newest',
  'Oldest',
  'SubscriptionStartOn-Low To High',
  'SubscriptionStartOn-High To Low',
  'SubscriptionExpireOn-Low To High',
  'SubscriptionExpireOn-High To Low'
];
export const sorting = () => {
  return sortingO.map(value => ({ key: value, text: value, value: value }));
};
// const cityO = ['Dwarka', 'Rohini', 'Gurugram'];
// export const city = () => {
//   return cityO.map(value => ({
//     key: value,
//     text: value,
//     value: value
//   }));
// };
//for dwarka only
const cityO = ['Dwarka'];
export const city = () => {
  return cityO.map(value => ({
    key: value,
    text: value,
    value: value
  }));
};

const userTypeA = ["admin", "executive", "guest"];
export const userType = () => {
  return userTypeA.map(value => ({
    key: value,
    text: value,
    value: value
  }));
};
const stateO = ['Delhi', 'Haryana', 'U P'];
export const state = () => {
  return stateO.map(value => ({
    key: value,
    text: value,
    value: value
  }));
};
const booleanOptionO = ['Yes'];
export const booleanOption = () => {
  return booleanOptionO.map(value => ({
    key: value,
    text: value,
    value: value
  }));
};
//############
const businessTypeO = [
  'Manufacturer',
  'Distributor',
  'Exporter',
  'Importer',
  'Wholesaler',
  'Dealer',
  'Service Provider'
];
export const businessType = () => {
  return businessTypeO.map(value => ({
    key: value,
    text: value,
    value: value
  }));
};
const companyStatusO = ['active', 'inactive'];
export const companyStatus = () => {
  return companyStatusO.map(value => ({
    key: value,
    text: value,
    value: value
  }));
};
const userStatusO = ['active', 'inactive'];
export const userStatus = () => {
  return userStatusO.map(value => ({
    key: value,
    text: value,
    value: value
  }));
};
const guestCompanyO = ['Yes', 'No'];
export const guestCompany = () => {
  return guestCompanyO.map(value => ({
    key: value,
    text: value,
    value: value
  }));
};
const recommendByO = ['User', 'Executive','Company','Others'];
export const recommendBy = () => {
  return recommendByO.map(value => ({
    key: value,
    text: value,
    value: value
  }));
};

//###############
const localityO = [
  'Sector-01',
  'Sector-02',
  'Sector-03',
  'Sector-04',
  'Sector-05',
  'Sector-06',
  'Sector-07',
  'Sector-08',
  'Sector-09',
  'Sector-10',
  'Sector-11',
  'Sector-12',
  'Sector-13',
  'Sector-14',
  'Sector-15',
  'Sector-16',
  'Sector-17',
  'Sector-18',
  'Sector-19',
  'Sector-20',
  'Sector-21',
  'Sector-22',
  'Sector-23',
  'Sector-24',
  'Sector-25',
  'Sector-26',
  'Sector-27',
  'Sector-28',
  'Sector-29',
  'Sector-30'
];
export const locality = () => {
  return localityO.map(value => ({
    key: value,
    text: value,
    value: value
  }));
};
