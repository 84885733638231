import axios from "axios";
import { toastr } from "react-redux-toastr";


//import { closeModal,openModal } from "./modalAction";
//import { hashHistory } from 'react-router';
import {
  FETCH_USER,
  TRANSPORT,
} from "./types";


//*************************************

//^^^^^^^^^^^^^^^^
export const createURL = (values) => async () => {
  try {

    const res = await axios.post(`/api/createURL`, values); // if we only put id instead of{ id: id } it result in object in req.body

  if(res.data.msg === 'errorAlready'){
    toastr.error('URL Already Exist');
  }

    if(res.data.msg ==='success'){
      toastr.success('URL Created Successfully');
    }
  } catch (error) {
    toastr.error("Oops", "Something went wrong.Please Try Again");
  }
};


//*************** */
//add / remove Dealer to My Group
export const addRemoveToMyGroup = (id) => async dispatch => {

try {
  const res = await axios.post("/api/AddRemoveToMyGroup",{
    id:id,

  } );
  if(res.data.msg === 'removed'){
    toastr.error("Dealer Removed from My Group.")
  }else {
    if(res.data.msg ==='added'){
      toastr.success('Dealer Added to My Group')
    }
  }

  dispatch({ type: FETCH_USER, payload: res.data.userA });
} catch (error) {
  toastr.error("Oops", "Something went wrong.Please Try Again shortlistAddRemoveResi");
}
};


//********************************** */
//fetch AppParameter
export const fetchAppParameter = () => async dispatch => {
  try {
    const res = await axios.get(`/api/AppParameter`);
    dispatch({ type: TRANSPORT, payload: res.data });

  } catch (error) {
    toastr.error("Oops", "Something went wrong.Please Try Again");
  }
};
//*************************************************

//update AppParameter
export const updateAppParameter = (values, history) => async () => {
  try {
     const res= await axios.put(`/api/AppParameterEdit`, values);

    history.push(`/appParameter/AppParameterDetail`);
    if(res.data.msg ==='success'){
      toastr.success('AppParameter Updated')
    }
  } catch (error) {
    toastr.error("Oops", "Something went wrong.Please Try Again");
  }
};
