import React, { Component } from "react";
import { connect } from "react-redux";
//import { Popup,Icon,Container } from "semantic-ui-react";
import { Switch, Route, } from "react-router-dom";
import {showBackButton,  } from "../../actions/authAction";

import ScrollTopButton from "../scroll/ScrollTopButton";
import ScrollBottomButton from "../scroll/ScrollBottomButton";
import PassChange from "./PassChange";
import ChangePass from "./ChangePass";
import PassReset from "./PassReset";
import FormPassReset from "./FormPassReset";
import Layout from "../layout/Layout";

class AuthDashboard extends Component {
  state = {
    openPop: false,
  };
  componentDidMount() {

    if(this.props.history.location.pathname !=='/'){
      this.props.showBackButton()
  }
    }

  render() {
      const { isAuthenticated,  } = this.props.auth;

      const authLinks = (



    <div>
    <div className='maindiv'>



          <Layout onLogoutClick={this.onLogoutClick}>




            <Switch>
            <Route exact path="/auth/passchange" component={PassChange} />
            <Route exact path="/auth/changepass/:id" component={ChangePass} />



            </Switch>

            </Layout >
            </div>

<div >
    <ScrollBottomButton scrollStepInPx="11550" delayInMs="16.66"/>

  <ScrollTopButton scrollStepInPx="11000" delayInMs="16.66"/>
</div>

</div>

      );

      // ************
      const guestLinks = (



        <div>

        <div className='maindiv'>

        <div>AuthDashboard </div>

              <Layout onLogoutClick={this.onLogoutClick}>




                <Switch>
                <Route
                  exact
                  path="/auth/formpassreset"
                  component={FormPassReset}
                />
                <Route exact path="/auth/passreset/:token" component={PassReset} />
                {/* <Route
                  exact
                  path="/auth/formpassreset"
                  component={FormPassReset}
                /> */}
                </Switch>

                </Layout >
                </div>

    <div >
        <ScrollBottomButton scrollStepInPx="11550" delayInMs="16.66"/>

      <ScrollTopButton scrollStepInPx="11000" delayInMs="16.66"/>
    </div>

    </div>

          );

    return (
      <div>
        {isAuthenticated ? (
          authLinks
        ) : (
          guestLinks
        )}
      </div>
    );
  }
}
const mapStateToProps = state => ({
  auth: state.auth
});
export default connect(
  mapStateToProps,
  { showBackButton, }
)(AuthDashboard);
